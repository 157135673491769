import React from "react"

import { withAccountActivate } from "./withAccountActivate"
import { Container } from "../../Styled/Container"
import { AccountActivateForm } from "./Form/AccountActivateForm"

export const AccountActivate = withAccountActivate(() => (
  <Container>
    <AccountActivateForm />
  </Container>
))
