import React from "react"
import { Link } from "gatsby"

import { withAccountActivateForm } from "./withAccountActivateForm"
import { Form, FormHeading, FormSubSubheading, FormInput, FormInputButton, FormInputWrapper, FormButton, FormError } from "../../../Styled/Form"
import { StyledContainer } from "../../Login/AccountLoginStyles"

export const AccountActivateForm = withAccountActivateForm(
  ({ data, errors, handleChange, handleSubmit, handleToggle, initError, invalid, loading, locales, routes, showPassword, success }) => (
    <StyledContainer>
      <Form onSubmit={handleSubmit}>
        {initError ? (
          <>
            <FormHeading noMargin>{locales?.activateTitle}</FormHeading>
            <FormSubSubheading>{initError}</FormSubSubheading>

            <FormButton as={Link} to={routes.ACCOUNT_LOGIN}>
              {locales?.resetLoginButton}
            </FormButton>
          </>
        ) : success ? (
          <>
            <FormHeading noMargin>{locales?.activateTitle}</FormHeading>
            <FormSubSubheading>{locales?.activateSuccess}</FormSubSubheading>

            <FormButton as={Link} to={routes.ACCOUNT_LOGIN}>
              {locales?.resetLoginButton}
            </FormButton>
          </>
        ) : (
          <>
            <FormHeading noMargin>{locales?.activateTitle}</FormHeading>
            <FormSubSubheading>{locales?.resetDescription}</FormSubSubheading>

            <FormInputWrapper>
              <FormInput
                collapsed={`true`}
                name={`password`}
                value={data?.password || ``}
                onChange={handleChange}
                placeholder={locales?.newPassword}
                disabled={loading}
                type={showPassword ? `text` : `password`}
                autoFocus
              />
            </FormInputWrapper>

            <FormInputWrapper>
              <FormInput
                collapsed={`true`}
                name={`passwordConfirm`}
                value={data?.passwordConfirm || ``}
                onChange={handleChange}
                placeholder={locales?.confirmPassword}
                disabled={loading}
                type={showPassword ? `text` : `password`}
              />
              <FormInputButton onClick={handleToggle} type={`button`}>
                {showPassword ? locales?.passwordHide : locales?.passwordShow}
              </FormInputButton>
            </FormInputWrapper>

            <FormButton disabled={invalid || loading} type={`submit`}>
              {locales?.save}
            </FormButton>

            {errors?.length > 0 && errors?.map(({ code, message }) => <FormError key={code}>{message}</FormError>)}
          </>
        )}
      </Form>
    </StyledContainer>
  )
)
